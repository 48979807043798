import React from "react";
//import { Link } from "gatsby";

import Layout from "../../components/layout";
import SEO from "../../components/seo";

import "bootstrap/dist/css/bootstrap.min.css";

const AboutMe = ({ location }) => (
  <Layout location={location}>
    <SEO title="自身について" />
    <p style={{fontSize: "150%"}}>私はゲーム業界で数年以上の経験を積み、技術および芸術の接点を専門にするプログラマーです。</p>
    <p>難しい問題を解いたり、熟練の同僚と共に働いたりするのが好きです。実力を付けるため、グラフィックスからゲームMODまで多くのプロジェクトを開発してきました。開発者キットでデバッグしたり、UIツールを作成したり、多くのソフトウェアリリースに貢献してきました。プレイヤーが夢中になる楽しいゲームを作ることが私の夢です。その夢を叶えられるゲーム開発会社を探しています。</p>

    <table class="verticalTable" style={{margin: "0 auto"}}>
      <tr>
        <th>現在の仕事の契約終了日</th>
        <td>2020年9月13日</td>
      </tr>
      <tr>
        <th>希望勤務地</th>
        <td>東京</td>
      </tr>
      <tr>
        <th>Linkedin</th>
        <td>
          <a href="https://www.linkedin.com/in/shonferguson/" target="_blank" rel="noopener noreferrer">
            https://www.linkedin.com/in/shonferguson/
          </a>
        </td>
      </tr>
      <tr>
        <th>Github</th>
        <td>
          <a href="https://github.com/shonferg" target="_blank" rel="noopener noreferrer">
            https://github.com/shonferg
          </a>
        </td>
      </tr>
      <tr>
        <th>SourceForge</th>
        <td>
          <a href="https://sourceforge.net/u/liztail/profile/" target="_blank" rel="noopener noreferrer">
            https://sourceforge.net/u/liztail/profile/
          </a>
        </td>
      </tr>
      <tr>
        <th>メール</th>
        <td>
          <a href="mailto:contact@shonf.com" target="_blank" rel="noopener noreferrer">
            contact@shonf.com
          </a>
        </td>
      </tr>
    </table>
  </Layout>
);

export default AboutMe;
